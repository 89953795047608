const FileCardData = [
  // -------------------- Product catalogs --------------------
  {
    id: "0492",
    type: "Product catalog",
    product: "Iceland, Denmark",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_EN.pdf",
    details: "PDF",
  },
  {
    id: "0475",
    type: "Product catalog",
    product: "Sweden",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_SV.pdf",
    details: "PDF",
  },
  {
    id: "0476",
    type: "Product catalog",
    product: "Norway",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_NB.pdf",
    details: "PDF",
  },
  {
    id: "0477",
    type: "Product catalog",
    product: "Finland",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_FI.pdf",
    details: "PDF",
  },
  {
    id: "0488",
    type: "Product catalog",
    product: "The Netherlands",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_NL.pdf",
    details: "PDF",
  },
  {
    id: "0487",
    type: "Product catalog",
    product: "Germany, Switzerland",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_DE.pdf",
    details: "PDF",
  },
  {
    id: "0501",
    type: "Product catalog",
    product: "Spain",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_ES.pdf",
    details: "PDF",
  },
  {
    id: "0502",
    type: "Product catalog",
    product: "Switzerland",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_FR.pdf",
    details: "PDF",
  },
  {
    id: "0393",
    type: "Product catalog",
    product: "Poland",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_PL.pdf",
    details: "PDF",
  },
  {
    id: "0509",
    type: "Product catalog",
    product: "Switzerland",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-catalog/plejd_product_catalog_IT.pdf",
    details: "PDF",
  },
  // -------------------- Manuals --------------------
  // ---------- English ----------
  {
    id: "0082",
    type: "Manual",
    product: "DIM-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0353",
    type: "Manual",
    product: "DIM-01-2P",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01-2P/plejd_manual_DIM-01-2P_EN.pdf",
    details: "PDF",
  },
  {
    id: "0306",
    type: "Manual",
    product: "DIM-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0089",
    type: "Manual",
    product: "CTR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0307",
    type: "Manual",
    product: "LED-10",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_EN.pdf",
    details: "PDF",
  },
  {
    id: "0308",
    type: "Manual",
    product: "REL-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0311",
    type: "Manual",
    product: "REL-01-2P",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_EN.pdf",
    details: "PDF",
  },
  {
    id: "0290",
    type: "Manual",
    product: "DAL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0533",
    type: "Manual",
    product: "EXT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0419",
    type: "Manual",
    product: "LED-75",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_EN.pdf",
    details: "PDF",
  },
  {
    id: "0840",
    type: "Manual",
    product: "GWY-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0543",
    type: "Manual",
    product: "WPH-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0555",
    type: "Manual",
    product: "SPR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/SPR-01/plejd_manual_SPR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1166-DWN-01",
    type: "Manual",
    product: "DWN-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-01/plejd_manual_DWN-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1166-DWN-02",
    type: "Manual",
    product: "DWN-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-02/plejd_manual_DWN-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0932",
    type: "Manual",
    product: "LST-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0942",
    type: "Manual",
    product: "OUT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-01/plejd_manual_OUT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1077",
    type: "Manual",
    product: "WMS-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1129",
    type: "Manual",
    product: "JAL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1136",
    type: "Manual",
    product: "WIN-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1145",
    type: "Manual",
    product: "CCL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CCL-01/plejd_manual_CCL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0595",
    type: "Manual",
    product: "WRT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WRT-01/plejd_manual_WRT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1223",
    type: "Manual",
    product: "VRI-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-01/plejd_manual_VRI-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0354",
    type: "Manual",
    product: "VRI-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-02/plejd_manual_VRI-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "1249",
    type: "Manual",
    product: "TRM-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/TRM-01/plejd_manual_TRM-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "1180",
    type: "Manual",
    product: "VRI-03",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-03/plejd_manual_VRI-03_EN.pdf",
    details: "PDF",
  },
  // ---------- Swedish ----------
  {
    id: "0004",
    type: "Manual",
    product: "DIM-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0014",
    type: "Manual",
    product: "DIM-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0008",
    type: "Manual",
    product: "CTR-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0023",
    type: "Manual",
    product: "LED-10",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_SV.pdf",
    details: "PDF",
  },
  {
    id: "0119",
    type: "Manual",
    product: "REL-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0395",
    type: "Manual",
    product: "REL-01-2P",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_SV.pdf",
    details: "PDF",
  },
  {
    id: "0346",
    type: "Manual",
    product: "DAL-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0534",
    type: "Manual",
    product: "EXT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0794",
    type: "Manual",
    product: "VRI-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-01/plejd_manual_VRI-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0420",
    type: "Manual",
    product: "LED-75",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_SV.pdf",
    details: "PDF",
  },
  {
    id: "0837",
    type: "Manual",
    product: "GWY-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0544",
    type: "Manual",
    product: "WPH-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0552",
    type: "Manual",
    product: "WRT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WRT-01/plejd_manual_WRT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0556",
    type: "Manual",
    product: "SPR-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/SPR-01/plejd_manual_SPR-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "1156-DWN-01",
    type: "Manual",
    product: "DWN-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-01/plejd_manual_DWN-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "1156-DWN-02",
    type: "Manual",
    product: "DWN-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-02/plejd_manual_DWN-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0931",
    type: "Manual",
    product: "LST-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0941",
    type: "Manual",
    product: "OUT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-01/plejd_manual_OUT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "1050",
    type: "Manual",
    product: "WMS-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "1126",
    type: "Manual",
    product: "JAL-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "1132",
    type: "Manual",
    product: "WIN-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "1122",
    type: "Manual",
    product: "CCL-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CCL-01/plejd_manual_CCL-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "1183",
    type: "Manual",
    product: "TRM-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/TRM-01/plejd_manual_TRM-01_SV.pdf",
    details: "PDF",
  },
  // ---------- Norwegian ----------
  {
    id: "0096",
    type: "Manual",
    product: "DIM-01-2P",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01-2P/plejd_manual_DIM-01-2P_NB.pdf",
    details: "PDF",
  },
  {
    id: "0144",
    type: "Manual",
    product: "DIM-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0184",
    type: "Manual",
    product: "CTR-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0185",
    type: "Manual",
    product: "LED-10",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_NB.pdf",
    details: "PDF",
  },
  {
    id: "0183",
    type: "Manual",
    product: "REL-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0113",
    type: "Manual",
    product: "REL-01-2P",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_NB.pdf",
    details: "PDF",
  },
  {
    id: "0347",
    type: "Manual",
    product: "DAL-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0535",
    type: "Manual",
    product: "EXT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0040",
    type: "Manual",
    product: "VRI-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-02/plejd_manual_VRI-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0421",
    type: "Manual",
    product: "LED-75",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_NB.pdf",
    details: "PDF",
  },
  {
    id: "0838",
    type: "Manual",
    product: "GWY-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0545",
    type: "Manual",
    product: "WPH-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0553",
    type: "Manual",
    product: "WRT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WRT-01/plejd_manual_WRT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0557",
    type: "Manual",
    product: "SPR-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/SPR-01/plejd_manual_SPR-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "1157-DWN-01",
    type: "Manual",
    product: "DWN-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-01/plejd_manual_DWN-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "1157-DWN-02",
    type: "Manual",
    product: "DWN-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-02/plejd_manual_DWN-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0933",
    type: "Manual",
    product: "LST-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0943",
    type: "Manual",
    product: "OUT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-01/plejd_manual_OUT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "1051",
    type: "Manual",
    product: "WMS-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "1127",
    type: "Manual",
    product: "JAL-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "1133",
    type: "Manual",
    product: "WIN-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "1123",
    type: "Manual",
    product: "CCL-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CCL-01/plejd_manual_CCL-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "1184",
    type: "Manual",
    product: "TRM-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/TRM-01/plejd_manual_TRM-01_NB.pdf",
    details: "PDF",
  },
  // ---------- Finnish ----------
  {
    id: "0076",
    type: "Manual",
    product: "DIM-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0128",
    type: "Manual",
    product: "DIM-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0133",
    type: "Manual",
    product: "CTR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0233",
    type: "Manual",
    product: "LED-10",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_FI.pdf",
    details: "PDF",
  },
  {
    id: "0232",
    type: "Manual",
    product: "REL-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0418",
    type: "Manual",
    product: "REL-01-2P",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_FI.pdf",
    details: "PDF",
  },
  {
    id: "0348",
    type: "Manual",
    product: "DAL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0536",
    type: "Manual",
    product: "EXT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0793",
    type: "Manual",
    product: "VRI-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-01/plejd_manual_VRI-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0422",
    type: "Manual",
    product: "LED-75",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_FI.pdf",
    details: "PDF",
  },
  {
    id: "0839",
    type: "Manual",
    product: "GWY-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0546",
    type: "Manual",
    product: "WPH-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0554",
    type: "Manual",
    product: "WRT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WRT-01/plejd_manual_WRT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0558",
    type: "Manual",
    product: "SPR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/SPR-01/plejd_manual_SPR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "1159-DWN-01",
    type: "Manual",
    product: "DWN-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-01/plejd_manual_DWN-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "1159-DWN-02",
    type: "Manual",
    product: "DWN-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-02/plejd_manual_DWN-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0936",
    type: "Manual",
    product: "LST-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "1134",
    type: "Manual",
    product: "WIN-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "1150",
    type: "Manual",
    product: "WMS-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0946",
    type: "Manual",
    product: "OUT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-01/plejd_manual_OUT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "1171",
    type: "Manual",
    product: "JAL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0133",
    type: "Manual",
    product: "CCL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CCL-01/plejd_manual_CCL-01_FI.pdf",
    details: "PDF",
  },
  // ---------- Dutch ----------
  {
    id: "0328",
    type: "Manual",
    product: "DIM-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0331",
    type: "Manual",
    product: "DIM-02",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_NL.pdf",
    details: "PDF",
  },
  {
    id: "0332",
    type: "Manual",
    product: "CTR-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0333",
    type: "Manual",
    product: "LED-10",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_NL.pdf",
    details: "PDF",
  },
  {
    id: "0335",
    type: "Manual",
    product: "REL-02",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_NL.pdf",
    details: "PDF",
  },
  {
    id: "0329",
    type: "Manual",
    product: "REL-01-2P",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_NL.pdf",
    details: "PDF",
  },
  {
    id: "0365",
    type: "Manual",
    product: "DAL-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0537",
    type: "Manual",
    product: "EXT-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0661",
    type: "Manual",
    product: "LED-75",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_NL.pdf",
    details: "PDF",
  },
  {
    id: "0841",
    type: "Manual",
    product: "GWY-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0547",
    type: "Manual",
    product: "WPH-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0559",
    type: "Manual",
    product: "SPR-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/SPR-01/plejd_manual_SPR-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "1158-DWN-01",
    type: "Manual",
    product: "DWN-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-01/plejd_manual_DWN-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "1158-DWN-02",
    type: "Manual",
    product: "DWN-02",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-02/plejd_manual_DWN-02_NL.pdf",
    details: "PDF",
  },
  {
    id: "0940",
    type: "Manual",
    product: "LST-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "1143",
    type: "Manual",
    product: "JAL-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "1144",
    type: "Manual",
    product: "WIN-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "1164",
    type: "Manual",
    product: "WMS-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0950",
    type: "Manual",
    product: "OUT-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-01/plejd_manual_OUT-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0332",
    type: "Manual",
    product: "CCL-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CCL-01/plejd_manual_CCL-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "1181",
    type: "Manual",
    product: "VRI-03",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-03/plejd_manual_VRI-03_NL.pdf",
    details: "PDF",
  },
  // ---------- German ----------
  {
    id: "0430",
    type: "Manual",
    product: "DIM-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0433",
    type: "Manual",
    product: "DIM-02",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_DE.pdf",
    details: "PDF",
  },
  {
    id: "0431",
    type: "Manual",
    product: "CTR-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0439",
    type: "Manual",
    product: "LED-10",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_DE.pdf",
    details: "PDF",
  },
  {
    id: "0436",
    type: "Manual",
    product: "REL-02",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_DE.pdf",
    details: "PDF",
  },
  {
    id: "0435",
    type: "Manual",
    product: "REL-01-2P",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_DE.pdf",
    details: "PDF",
  },
  {
    id: "0432",
    type: "Manual",
    product: "DAL-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0538",
    type: "Manual",
    product: "EXT-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0662",
    type: "Manual",
    product: "LED-75",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_DE.pdf",
    details: "PDF",
  },
  {
    id: "0842",
    type: "Manual",
    product: "GWY-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0548",
    type: "Manual",
    product: "WPH-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0934",
    type: "Manual",
    product: "LST-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "1128",
    type: "Manual",
    product: "JAL-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "1135",
    type: "Manual",
    product: "WIN-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "1153",
    type: "Manual",
    product: "WMS-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0601",
    type: "Manual",
    product: "SPR-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/SPR-01/plejd_manual_SPR-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "1182",
    type: "Manual",
    product: "VRI-03",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/VRI-03/plejd_manual_VRI-03_DE.pdf",
    details: "PDF",
  },
  // ---------- Spanish ----------
  {
    id: "0440",
    type: "Manual",
    product: "DIM-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0443",
    type: "Manual",
    product: "DIM-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0441",
    type: "Manual",
    product: "CTR-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0449",
    type: "Manual",
    product: "LED-10",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_ES.pdf",
    details: "PDF",
  },
  {
    id: "0446",
    type: "Manual",
    product: "REL-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0445",
    type: "Manual",
    product: "REL-01-2P",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_ES.pdf",
    details: "PDF",
  },
  {
    id: "0442",
    type: "Manual",
    product: "DAL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0539",
    type: "Manual",
    product: "EXT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0663",
    type: "Manual",
    product: "LED-75",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_ES.pdf",
    details: "PDF",
  },
  {
    id: "0444",
    type: "Manual",
    product: "GWY-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0549",
    type: "Manual",
    product: "WPH-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0560",
    type: "Manual",
    product: "SPR-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/SPR-01/plejd_manual_SPR-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "1160-DWN-01",
    type: "Manual",
    product: "DWN-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-01/plejd_manual_DWN-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "1160-DWN-02",
    type: "Manual",
    product: "DWN-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DWN-02/plejd_manual_DWN-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0935",
    type: "Manual",
    product: "LST-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "1130",
    type: "Manual",
    product: "JAL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "1137",
    type: "Manual",
    product: "WIN-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "1148",
    type: "Manual",
    product: "WMS-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0945",
    type: "Manual",
    product: "OUT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-01/plejd_manual_OUT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0441",
    type: "Manual",
    product: "CCL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CCL-01/plejd_manual_CCL-01_ES.pdf",
    details: "PDF",
  },
  // ---------- French ----------
  {
    id: "0450",
    type: "Manual",
    product: "DIM-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0453",
    type: "Manual",
    product: "DIM-02",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_FR.pdf",
    details: "PDF",
  },
  {
    id: "0451",
    type: "Manual",
    product: "CTR-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0459",
    type: "Manual",
    product: "LED-10",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_FR.pdf",
    details: "PDF",
  },
  {
    id: "0456",
    type: "Manual",
    product: "REL-02",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_FR.pdf",
    details: "PDF",
  },
  {
    id: "0455",
    type: "Manual",
    product: "REL-01-2P",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_FR.pdf",
    details: "PDF",
  },
  {
    id: "0452",
    type: "Manual",
    product: "DAL-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0540",
    type: "Manual",
    product: "EXT-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0664",
    type: "Manual",
    product: "LED-75",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_FR.pdf",
    details: "PDF",
  },
  {
    id: "0454",
    type: "Manual",
    product: "GWY-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0938",
    type: "Manual",
    product: "LST-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "1131",
    type: "Manual",
    product: "JAL-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "1138",
    type: "Manual",
    product: "WIN-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "1163",
    type: "Manual",
    product: "WMS-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_FR.pdf",
    details: "PDF",
  },
  // ---------- Polish ----------
  {
    id: "0460",
    type: "Manual",
    product: "DIM-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0463",
    type: "Manual",
    product: "DIM-02",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_PL.pdf",
    details: "PDF",
  },
  {
    id: "0461",
    type: "Manual",
    product: "CTR-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0469",
    type: "Manual",
    product: "LED-10",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_PL.pdf",
    details: "PDF",
  },
  {
    id: "0466",
    type: "Manual",
    product: "REL-02",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_PL.pdf",
    details: "PDF",
  },
  {
    id: "0465",
    type: "Manual",
    product: "REL-01-2P",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_PL.pdf",
    details: "PDF",
  },
  {
    id: "0462",
    type: "Manual",
    product: "DAL-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0541",
    type: "Manual",
    product: "EXT-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0665",
    type: "Manual",
    product: "LED-75",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_PL.pdf",
    details: "PDF",
  },
  {
    id: "0843",
    type: "Manual",
    product: "GWY-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0551",
    type: "Manual",
    product: "WPH-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WPH-01/plejd_manual_WPH-01_PL.pdf",
    details: "PDF",
  },
  // ---------- Italian ----------
  {
    id: "0856",
    type: "Manual",
    product: "DIM-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-01/plejd_manual_DIM-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0857",
    type: "Manual",
    product: "DIM-02",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DIM-02/plejd_manual_DIM-02_IT.pdf",
    details: "PDF",
  },
  {
    id: "0860",
    type: "Manual",
    product: "CTR-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/CTR-01/plejd_manual_CTR-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0861",
    type: "Manual",
    product: "LED-10",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-10/plejd_manual_LED-10_IT.pdf",
    details: "PDF",
  },
  {
    id: "0859",
    type: "Manual",
    product: "REL-02",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-02/plejd_manual_REL-02_IT.pdf",
    details: "PDF",
  },
  {
    id: "0858",
    type: "Manual",
    product: "REL-01-2P",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/REL-01-2P/plejd_manual_REL-01-2P_IT.pdf",
    details: "PDF",
  },
  {
    id: "0862",
    type: "Manual",
    product: "DAL-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/DAL-01/plejd_manual_DAL-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0542",
    type: "Manual",
    product: "EXT-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/EXT-01/plejd_manual_EXT-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0863",
    type: "Manual",
    product: "LED-75",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LED-75/plejd_manual_LED-75_IT.pdf",
    details: "PDF",
  },
  {
    id: "0864",
    type: "Manual",
    product: "GWY-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/GWY-01/plejd_manual_GWY-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0939",
    type: "Manual",
    product: "LST-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/LST-01/plejd_manual_LST-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "1140",
    type: "Manual",
    product: "JAL-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/JAL-01/plejd_manual_JAL-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "1139",
    type: "Manual",
    product: "WIN-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WIN-01/plejd_manual_WIN-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "1151",
    type: "Manual",
    product: "WMS-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/WMS-01/plejd_manual_WMS-01_IT.pdf",
    details: "PDF",
  },
  // -------------------- DoC --------------------
  // ---------- English ----------
  {
    id: "com-0001",
    type: "Declaration of conformity",
    product: "DIM-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-01/plejd_doc_DIM-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0106",
    type: "Declaration of conformity",
    product: "DIM-01-2P",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-01-2P/plejd_doc_DIM-01-2P_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0070",
    type: "Declaration of conformity",
    product: "DIM-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-02/plejd_doc_DIM-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0023",
    type: "Declaration of conformity",
    product: "CTR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CTR-01/plejd_doc_CTR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0036",
    type: "Declaration of conformity",
    product: "LED-10",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-10/plejd_doc_LED-10_EN.pdf",
    details: "PDF",
  },
  {
    id: "0075",
    type: "Declaration of conformity",
    product: "REL-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-02/plejd_doc_REL-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0076",
    type: "Declaration of conformity",
    product: "REL-01-2P",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-01-2P/plejd_doc_REL-01-2P_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0063",
    type: "Declaration of conformity",
    product: "DAL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DAL-01/plejd_doc_DAL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0056",
    type: "Declaration of conformity",
    product: "EXT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/EXT-01/plejd_doc_EXT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0043",
    type: "Declaration of conformity",
    product: "VRI-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/VRI-01/plejd_doc_VRI-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0107",
    type: "Declaration of conformity",
    product: "VRI-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/VRI-02/plejd_doc_VRI-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0418",
    type: "Declaration of conformity",
    product: "LED-75",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-75/plejd_doc_LED-75_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0078",
    type: "Declaration of conformity",
    product: "GWY-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/GWY-01/plejd_doc_GWY-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0045",
    type: "Declaration of conformity",
    product: "WPH-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WPH-01/plejd_doc_WPH-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0051",
    type: "Declaration of conformity",
    product: "WRT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WRT-01/plejd_doc_WRT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0090",
    type: "Declaration of conformity",
    product: "WRT-01-R",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WRT-01-R/plejd_doc_WRT-01-R_EN.pdf",
    details: "PDF",
  },
  {
    id: "0032",
    type: "Declaration of conformity",
    product: "RTR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/RTR-01/plejd_doc_RTR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0395",
    type: "Declaration of conformity",
    product: "BAT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/BAT-01/plejd_doc_BAT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0271",
    type: "Declaration of conformity",
    product: "SPR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/SPR-01/plejd_doc_SPR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0467",
    type: "Declaration of conformity",
    product: "DWN-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DWN-01/plejd_doc_DWN-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0468",
    type: "Declaration of conformity",
    product: "DWN-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DWN-02/plejd_doc_DWN-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0489",
    type: "Declaration of conformity",
    product: "LST-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LST-01/plejd_doc_LST-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0027",
    type: "Declaration of conformity",
    product: "WMS-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WMS-01/plejd_doc_WMS-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0007",
    type: "Declaration of conformity",
    product: "OUT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/OUT-01/plejd_doc_OUT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0031",
    type: "Declaration of conformity",
    product: "CCL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CCL-01/plejd_doc_CCL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0028",
    type: "Declaration of conformity",
    product: "WIN-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WIN-01/plejd_doc_WIN-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0032",
    type: "Declaration of conformity",
    product: "JAL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/JAL-01/plejd_doc_JAL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0094",
    type: "Declaration of conformity",
    product: "TRM-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/TRM-01/plejd_doc_TRM-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "com-0095",
    type: "Declaration of conformity",
    product: "VRI-03",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/VRI-03/plejd_doc_VRI-03_EN.pdf",
    details: "PDF",
  },
  // ---------- Finnish ----------
  {
    id: "com-0008",
    type: "Declaration of conformity",
    product: "DIM-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-01/plejd_doc_DIM-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0071",
    type: "Declaration of conformity",
    product: "DIM-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-02/plejd_doc_DIM-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0024",
    type: "Declaration of conformity",
    product: "CTR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CTR-01/plejd_doc_CTR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0038",
    type: "Declaration of conformity",
    product: "LED-10",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-10/plejd_doc_LED-10_FI.pdf",
    details: "PDF",
  },
  {
    id: "0317",
    type: "Declaration of conformity",
    product: "REL-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-02/plejd_doc_REL-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0316",
    type: "Declaration of conformity",
    product: "REL-01-2P",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-01-2P/plejd_doc_REL-01-2P_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0064",
    type: "Declaration of conformity",
    product: "DAL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DAL-01/plejd_doc_DAL-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0059",
    type: "Declaration of conformity",
    product: "EXT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/EXT-01/plejd_doc_EXT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0044",
    type: "Declaration of conformity",
    product: "VRI-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/VRI-01/plejd_doc_VRI-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0419",
    type: "Declaration of conformity",
    product: "LED-75",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-75/plejd_doc_LED-75_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0080",
    type: "Declaration of conformity",
    product: "GWY-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/GWY-01/plejd_doc_GWY-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0403",
    type: "Declaration of conformity",
    product: "RTR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/RTR-01/plejd_doc_RTR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0396",
    type: "Declaration of conformity",
    product: "BAT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/BAT-01/plejd_doc_BAT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0318",
    type: "Declaration of conformity",
    product: "SPR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/SPR-01/plejd_doc_SPR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0478",
    type: "Declaration of conformity",
    product: "DWN-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DWN-01/plejd_doc_DWN-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0490",
    type: "Declaration of conformity",
    product: "DWN-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DWN-02/plejd_doc_DWN-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0491",
    type: "Declaration of conformity",
    product: "LST-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LST-01/plejd_doc_LST-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0030",
    type: "Declaration of conformity",
    product: "WIN-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WIN-01/plejd_doc_WIN-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0019",
    type: "Declaration of conformity",
    product: "WMS-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WMS-01/plejd_doc_WMS-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0034",
    type: "Declaration of conformity",
    product: "JAL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/JAL-01/plejd_doc_JAL-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0016",
    type: "Declaration of conformity",
    product: "OUT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/OUT-01/plejd_doc_OUT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "com-0091",
    type: "Declaration of conformity",
    product: "CCL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CCL-01/plejd_doc_CCL-01_FI.pdf",
    details: "PDF",
  },
  // ---------- German ----------
  {
    id: "com-0010",
    type: "Declaration of conformity",
    product: "DIM-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-01/plejd_doc_DIM-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0072",
    type: "Declaration of conformity",
    product: "DIM-02",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-02/plejd_doc_DIM-02_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0086",
    type: "Declaration of conformity",
    product: "CTR-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CTR-01/plejd_doc_CTR-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0035",
    type: "Declaration of conformity",
    product: "LED-10",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-10/plejd_doc_LED-10_DE.pdf",
    details: "PDF",
  },
  {
    id: "0329",
    type: "Declaration of conformity",
    product: "REL-02",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-02/plejd_doc_REL-02_DE.pdf",
    details: "PDF",
  },
  {
    id: "0328",
    type: "Declaration of conformity",
    product: "REL-01-2P",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-01-2P/plejd_doc_REL-01-2P_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0062",
    type: "Declaration of conformity",
    product: "DAL-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DAL-01/plejd_doc_DAL-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0057",
    type: "Declaration of conformity",
    product: "EXT-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/EXT-01/plejd_doc_EXT-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0428",
    type: "Declaration of conformity",
    product: "LED-75",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-75/plejd_doc_LED-75_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0079",
    type: "Declaration of conformity",
    product: "GWY-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/GWY-01/plejd_doc_GWY-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0397",
    type: "Declaration of conformity",
    product: "BAT-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/BAT-01/plejd_doc_BAT-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0088",
    type: "Declaration of conformity",
    product: "JAL-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/JAL-01/plejd_doc_JAL-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "com-0020",
    type: "Declaration of conformity",
    product: "WMS-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WMS-01/plejd_doc_WMS-01_DE.pdf",
    details: "PDF",
  },
  // ---------- Spanish ----------
  {
    id: "com-0009",
    type: "Declaration of conformity",
    product: "DIM-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-01/plejd_doc_DIM-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0073",
    type: "Declaration of conformity",
    product: "DIM-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-02/plejd_doc_DIM-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0025",
    type: "Declaration of conformity",
    product: "CTR-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CTR-01/plejd_doc_CTR-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0037",
    type: "Declaration of conformity",
    product: "LED-10",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-10/plejd_doc_LED-10_ES.pdf",
    details: "PDF",
  },
  {
    id: "0341",
    type: "Declaration of conformity",
    product: "REL-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-02/plejd_doc_REL-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0340",
    type: "Declaration of conformity",
    product: "REL-01-2P",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-01-2P/plejd_doc_REL-01-2P_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0065",
    type: "Declaration of conformity",
    product: "DAL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DAL-01/plejd_doc_DAL-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0058",
    type: "Declaration of conformity",
    product: "EXT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/EXT-01/plejd_doc_EXT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0430",
    type: "Declaration of conformity",
    product: "LED-75",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-75/plejd_doc_LED-75_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0081",
    type: "Declaration of conformity",
    product: "GWY-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/GWY-01/plejd_doc_GWY-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0398",
    type: "Declaration of conformity",
    product: "BAT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/BAT-01/plejd_doc_BAT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0342",
    type: "Declaration of conformity",
    product: "SPR-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/SPR-01/plejd_doc_SPR-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0002",
    type: "Declaration of conformity",
    product: "DWN-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DWN-01/plejd_doc_DWN-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0003",
    type: "Declaration of conformity",
    product: "DWN-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DWN-02/plejd_doc_DWN-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0004",
    type: "Declaration of conformity",
    product: "LST-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LST-01/plejd_doc_LST-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0005",
    type: "Declaration of conformity",
    product: "WPH-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WPH-01/plejd_doc_WPH-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0052",
    type: "Declaration of conformity",
    product: "WRT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WRT-01/plejd_doc_WRT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0029",
    type: "Declaration of conformity",
    product: "WIN-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WIN-01/plejd_doc_WIN-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0033",
    type: "Declaration of conformity",
    product: "JAL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/JAL-01/plejd_doc_JAL-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0021",
    type: "Declaration of conformity",
    product: "WMS-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WMS-01/plejd_doc_WMS-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0017",
    type: "Declaration of conformity",
    product: "OUT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/OUT-01/plejd_doc_OUT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "com-0093",
    type: "Declaration of conformity",
    product: "CCL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CCL-01/plejd_doc_CCL-01_ES.pdf",
    details: "PDF",
  },
  // ---------- French ----------
  {
    id: "com-0012",
    type: "Declaration of conformity",
    product: "DIM-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-01/plejd_doc_DIM-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "com-0074",
    type: "Declaration of conformity",
    product: "DIM-02",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-02/plejd_doc_DIM-02_FR.pdf",
    details: "PDF",
  },
  {
    id: "com-0087",
    type: "Declaration of conformity",
    product: "CTR-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CTR-01/plejd_doc_CTR-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "com-0039",
    type: "Declaration of conformity",
    product: "LED-10",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-10/plejd_doc_LED-10_FR.pdf",
    details: "PDF",
  },
  {
    id: "0353",
    type: "Declaration of conformity",
    product: "REL-02",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-02/plejd_doc_REL-02_FR.pdf",
    details: "PDF",
  },
  {
    id: "0352",
    type: "Declaration of conformity",
    product: "REL-01-2P",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-01-2P/plejd_doc_REL-01-2P_FR.pdf",
    details: "PDF",
  },
  {
    id: "com-0066",
    type: "Declaration of conformity",
    product: "DAL-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DAL-01/plejd_doc_DAL-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "com-0060",
    type: "Declaration of conformity",
    product: "EXT-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/EXT-01/plejd_doc_EXT-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0470",
    type: "Declaration of conformity",
    product: "LED-75",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-75/plejd_doc_LED-75_FR.pdf",
    details: "PDF",
  },
  {
    id: "com-0082",
    type: "Declaration of conformity",
    product: "GWY-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/GWY-01/plejd_doc_GWY-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0399",
    type: "Declaration of conformity",
    product: "BAT-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/BAT-01/plejd_doc_BAT-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "com-0089",
    type: "Declaration of conformity",
    product: "JAL-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/JAL-01/plejd_doc_JAL-01_FR.pdf",
    details: "PDF",
  },
  // ---------- Polish ----------
  {
    id: "com-0011",
    type: "Declaration of conformity",
    product: "DIM-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-01/plejd_doc_DIM-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "com-0075",
    type: "Declaration of conformity",
    product: "DIM-02",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DIM-02/plejd_doc_DIM-02_PL.pdf",
    details: "PDF",
  },
  {
    id: "com-0026",
    type: "Declaration of conformity",
    product: "CTR-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/CTR-01/plejd_doc_CTR-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "com-0041",
    type: "Declaration of conformity",
    product: "LED-10",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-10/plejd_doc_LED-10_PL.pdf",
    details: "PDF",
  },
  {
    id: "0377",
    type: "Declaration of conformity",
    product: "REL-02",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-02/plejd_doc_REL-02_PL.pdf",
    details: "PDF",
  },
  {
    id: "0376",
    type: "Declaration of conformity",
    product: "REL-01-2P",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/REL-01-2P/plejd_doc_REL-01-2P_PL.pdf",
    details: "PDF",
  },
  {
    id: "com-0067",
    type: "Declaration of conformity",
    product: "DAL-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/DAL-01/plejd_doc_DAL-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "com-0061",
    type: "Declaration of conformity",
    product: "EXT-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/EXT-01/plejd_doc_EXT-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0429",
    type: "Declaration of conformity",
    product: "LED-75",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/LED-75/plejd_doc_LED-75_PL.pdf",
    details: "PDF",
  },
  {
    id: "com-0083",
    type: "Declaration of conformity",
    product: "GWY-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/GWY-01/plejd_doc_GWY-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "com-0049",
    type: "Declaration of conformity",
    product: "WPH-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/WPH-01/plejd_doc_WPH-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0401",
    type: "Declaration of conformity",
    product: "BAT-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/BAT-01/plejd_doc_BAT-01_PL.pdf",
    details: "PDF",
  },
  // -------------------- Sales material --------------------
  // ---------- English ----------
  {
    id: "0123",
    type: "Sales material",
    product: "Installer flyer",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/installer-flyer/plejd_installer_flyer_EN.pdf",
    details: "PDF",
  },
  {
    id: "0447",
    type: "Sales material",
    product: "Consumer flyer",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_EN.pdf",
    details: "PDF",
  },
  // ---------- Swedish ----------
  {
    id: "0120",
    type: "Sales material",
    product: "Installer flyer",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/installer-flyer/plejd_installer_flyer_SV.pdf",
    details: "PDF",
  },
  {
    id: "0394",
    type: "Sales material",
    product: "Installer flyer (fillable)",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/installer-flyer/plejd_installer_flyer_fillable_SV.pdf",
    details: "Fillable PDF",
  },
  {
    id: "0445",
    type: "Sales material",
    product: "Consumer flyer",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_SV.pdf",
    details: "PDF",
  },
  {
    id: "0531",
    type: "Sales material",
    product: "Consumer flyer (fillable)",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_fillable_SV.pdf",
    details: "Fillable PDF",
  },
  {
    id: "0272",
    type: "Sales material",
    product: "Commercial flyer",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/commercial-flyer/plejd_commercial_flyer_SV.pdf",
    details: "PDF",
  },
  {
    id: "0427",
    type: "Sales material",
    product: "Commercial flyer (fillable)",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/commercial-flyer/plejd_commercial_flyer_fillable_SV.pdf",
    details: "Fillable PDF",
  },
  // ---------- Norwegian ----------
  {
    id: "0121",
    type: "Sales material",
    product: "Installer flyer",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/installer-flyer/plejd_installer_flyer_NB.pdf",
    details: "PDF",
  },
  {
    id: "0446",
    type: "Sales material",
    product: "Consumer flyer",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_NB.pdf",
    details: "PDF",
  },
  {
    id: "0450",
    type: "Sales material",
    product: "Commercial flyer",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/commercial-flyer/plejd_commercial_flyer_NB.pdf",
    details: "PDF",
  },
  // ---------- German ----------
  {
    id: "0426",
    type: "Sales material",
    product: "Installer flyer",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/installer-flyer/plejd_installer_flyer_DE.pdf",
    details: "PDF",
  },
  {
    id: "0448",
    type: "Sales material",
    product: "Consumer flyer",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_DE.pdf",
    details: "PDF",
  },
  {
    id: "0532",
    type: "Sales material",
    product: "Consumer flyer",
    language: "German (Switzerland)",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_DE_CH.pdf",
    details: "PDF",
  },
  // ---------- Finnish ----------
  {
    id: "0122",
    type: "Sales material",
    product: "Installer flyer",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/installer-flyer/plejd_installer_flyer_FI.pdf",
    details: "PDF",
  },
  {
    id: "0452",
    type: "Sales material",
    product: "Consumer flyer",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_FI.pdf",
    details: "PDF",
  },
  // ---------- Dutch ----------
  {
    id: "0453",
    type: "Sales material",
    product: "Consumer flyer",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_NL.pdf",
    details: "PDF",
  },
  // ---------- French ----------
  {
    id: "0449",
    type: "Sales material",
    product: "Consumer flyer",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_FR.pdf",
    details: "PDF",
  },
  // ---------- Spanish ----------
  {
    id: "0600",
    type: "Sales material",
    product: "Consumer flyer",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/consumer-flyer/plejd_consumer_flyer_ES.pdf",
    details: "PDF",
  },
  // -------------------- Product images --------------------
  // ---------- DIM-01 ----------
  {
    id: "product-image-dim-01-rgb-png",
    type: "Product image",
    product: "DIM-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-01/plejd_product_image_DIM-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dim-01-rgb-jpg",
    type: "Product image",
    product: "DIM-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-01/plejd_product_image_DIM-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dim-01-cmyk-tif",
    type: "Product image",
    product: "DIM-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-01/plejd_product_image_DIM-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- DIM-01-2P ----------
  {
    id: "product-image-dim-01-2p-rgb-png",
    type: "Product image",
    product: "DIM-01-2P",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-01-2P/plejd_product_image_DIM-01-2P_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dim-01-2p-rgb-jpg",
    type: "Product image",
    product: "DIM-01-2P",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-01-2P/plejd_product_image_DIM-01-2P_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dim-01-2p-cmyk-tif",
    type: "Product image",
    product: "DIM-01-2P",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-01-2P/plejd_product_image_DIM-01-2P_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- DIM-02 ----------
  {
    id: "product-image-dim-02-rgb-png",
    type: "Product image",
    product: "DIM-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-02/plejd_product_image_DIM-02_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dim-02-rgb-jpg",
    type: "Product image",
    product: "DIM-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-02/plejd_product_image_DIM-02_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dim-02-cmyk-tif",
    type: "Product image",
    product: "DIM-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DIM-02/plejd_product_image_DIM-02_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- CTR-01 ----------
  {
    id: "product-image-ctr-01-rgb-png",
    type: "Product image",
    product: "CTR-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/CTR-01/plejd_product_image_CTR-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-ctr-01-rgb-jpg",
    type: "Product image",
    product: "CTR-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/CTR-01/plejd_product_image_CTR-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-ctr-01-cmyk-tif",
    type: "Product image",
    product: "CTR-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/CTR-01/plejd_product_image_CTR-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- LED-10 ----------
  {
    id: "product-image-led-10-rgb-png",
    type: "Product image",
    product: "LED-10",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LED-10/plejd_product_image_LED-10_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-led-10-rgb-jpg",
    type: "Product image",
    product: "LED-10",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LED-10/plejd_product_image_LED-10_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-led-10-cmyk-tif",
    type: "Product image",
    product: "LED-10",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LED-10/plejd_product_image_LED-10_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- REL-02 ----------
  {
    id: "product-image-rel-02-rgb-png",
    type: "Product image",
    product: "REL-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/REL-02/plejd_product_image_REL-02_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rel-02-rgb-jpg",
    type: "Product image",
    product: "REL-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/REL-02/plejd_product_image_REL-02_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rel-02-cmyk-tif",
    type: "Product image",
    product: "REL-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/REL-02/plejd_product_image_REL-02_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- REL-01-2P ----------
  {
    id: "product-image-rel-01-2p-rgb-png",
    type: "Product image",
    product: "REL-01-2P",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/REL-01-2P/plejd_product_image_REL-01-2P_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rel-01-2p-rgb-jpg",
    type: "Product image",
    product: "REL-01-2P",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/REL-01-2P/plejd_product_image_REL-01-2P_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rel-01-2p-cmyk-tif",
    type: "Product image",
    product: "REL-01-2P",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/REL-01-2P/plejd_product_image_REL-01-2P_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- DAL-01 ----------
  {
    id: "product-image-dal-01-rgb-png",
    type: "Product image",
    product: "DAL-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DAL-01/plejd_product_image_DAL-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dal-01-rgb-jpg",
    type: "Product image",
    product: "DAL-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DAL-01/plejd_product_image_DAL-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dal-01-cmyk-tif",
    type: "Product image",
    product: "DAL-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/DAL-01/plejd_product_image_DAL-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- EXT-01 ----------
  {
    id: "product-image-ext-01-rgb-png",
    type: "Product image",
    product: "EXT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/EXT-01/plejd_product_image_EXT-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-ext-01-rgb-jpg",
    type: "Product image",
    product: "EXT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/EXT-01/plejd_product_image_EXT-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-ext-01-cmyk-tif",
    type: "Product image",
    product: "EXT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/EXT-01/plejd_product_image_EXT-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- LED-75 ----------
  {
    id: "product-image-led-75-rgb-png",
    type: "Product image",
    product: "LED-75",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LED-75/plejd_product_image_LED-75_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-led-75-rgb-jpg",
    type: "Product image",
    product: "LED-75",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LED-75/plejd_product_image_LED-75_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-led-75-cmyk-tif",
    type: "Product image",
    product: "LED-75",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LED-75/plejd_product_image_LED-75_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-01-SW ----------
  {
    id: "product-image-vri-01-sw-rgb-png",
    type: "Product image",
    product: "VRI-01",
    language: "",
    title: "VRI-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-01/plejd_product_image_VRI-01-SW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-01-sw-rgb-jpg",
    type: "Product image",
    product: "VRI-01",
    language: "",
    title: "VRI-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-01/plejd_product_image_VRI-01-SW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-01-sw-cmyk-tif",
    type: "Product image",
    product: "VRI-01",
    language: "",
    title: "VRI-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-01/plejd_product_image_VRI-01-SW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-02-SW ----------
  {
    id: "product-image-vri-02-sw-rgb-png",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-SW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-SW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-sw-rgb-jpg",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-SW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-SW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-sw-cmyk-tif",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-SW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-SW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-02-EW ----------
  {
    id: "product-image-vri-02-ew-rgb-png",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-EW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-EW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-ew-rgb-jpg",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-EW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-EW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-ew-cmyk-tif",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-EW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-EW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-02-EB ----------
  {
    id: "product-image-vri-02-eb-rgb-png",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-EB",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-EB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-eb-rgb-jpg",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-EB",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-EB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-eb-cmyk-tif",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-EB",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-EB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-02-SA ----------
  {
    id: "product-image-vri-02-sa-rgb-png",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-SA",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-SA_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-sa-rgb-jpg",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-SA",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-SA_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-sa-cmyk-tif",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-SA",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-SA_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-02-AW ----------
  {
    id: "product-image-vri-02-aw-rgb-png",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-AW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-AW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-aw-rgb-jpg",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-AW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-AW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-aw-cmyk-tif",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-AW",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-AW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-02-AB ----------
  {
    id: "product-image-vri-02-ab-rgb-png",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-AB",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-AB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-ab-rgb-jpg",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-AB",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-AB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-02-ab-cmyk-tif",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02-AB",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02-AB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- GWY-01 ----------
  {
    id: "product-image-gwy-01-rgb-png",
    type: "Product image",
    product: "GWY-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/GWY-01/plejd_product_image_GWY-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-gwy-01-rgb-jpg",
    type: "Product image",
    product: "GWY-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/GWY-01/plejd_product_image_GWY-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-gwy-01-cmyk-tif",
    type: "Product image",
    product: "GWY-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/GWY-01/plejd_product_image_GWY-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-SW ----------
  {
    id: "product-image-wph-01-sw-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-SW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-sw-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-SW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-sw-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-SW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-EW ----------
  {
    id: "product-image-wph-01-ew-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-EW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-ew-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-EW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-ew-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-EW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-EB ----------
  {
    id: "product-image-wph-01-eb-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-EB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-eb-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-EB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-eb-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-EB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-SA ----------
  {
    id: "product-image-wph-01-sa-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-SA_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-sa-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-SA_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-sa-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-SA_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-55W ----------
  {
    id: "product-image-wph-01-55w-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-55W",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-55W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-55w-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-55W",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-55W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-55w-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-55W",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-55W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-55B ----------
  {
    id: "product-image-wph-01-55b-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-55B",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-55B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-55b-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-55B",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-55B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-55b-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-55B",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-55B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-AW ----------
  {
    id: "product-image-wph-01-aw-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-AW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-aw-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-AW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-aw-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-AW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-AB ----------
  {
    id: "product-image-wph-01-ab-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-AB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-ab-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-AB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-ab-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-AB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-A-SW ----------
  {
    id: "product-image-wph-01-a-sw-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-SW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-SW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-a-sw-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-SW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-SW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-a-sw-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-SW",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-SW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-A-55W ----------
  {
    id: "product-image-wph-01-a-55w-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-55W",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-55W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-a-55w-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-55W",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-55W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-a-55w-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-55W",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-55W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WPH-01-A-55B ----------
  {
    id: "product-image-wph-01-a-55b-rgb-png",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-55B",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-55B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-a-55b-rgb-jpg",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-55B",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-55B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wph-01-a-55b-cmyk-tif",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A-55B",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A-55B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-SW ----------
  {
    id: "product-image-wrt-01-sw-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-SW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-sw-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-SW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-sw-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-SW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-EW ----------
  {
    id: "product-image-wrt-01-ew-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-EW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-ew-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-EW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-ew-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-EW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-EB ----------
  {
    id: "product-image-wrt-01-eb-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-EB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-eb-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-EB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-eb-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-EB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-SA ----------
  {
    id: "product-image-wrt-01-sa-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-SA_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-sa-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-SA_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-sa-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-SA_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-AW ----------
  {
    id: "product-image-wrt-01-aw-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-AW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-aw-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-AW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-aw-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-AW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-AB ----------
  {
    id: "product-image-wrt-01-ab-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-AB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-ab-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-AB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-ab-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-AB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-RB ----------
  {
    id: "product-image-wrt-01-rb-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-RB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-RB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-rb-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-RB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-RB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-rb-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-RB",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-RB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WRT-01-RW ----------
  {
    id: "product-image-wrt-01-rw-rgb-png",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-RW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-RW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-rw-rgb-jpg",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-RW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-RW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wrt-01-rw-cmyk-tif",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-RW",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-RW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-SW ----------
  {
    id: "product-image-rtr-01-sw-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-SW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-sw-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-SW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-sw-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-SW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-EW ----------
  {
    id: "product-image-rtr-01-ew-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-EW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-ew-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-EW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-ew-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-EW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-EB ----------
  {
    id: "product-image-rtr-01-eb-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-EB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-eb-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-EB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-eb-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-EB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-SA ----------
  {
    id: "product-image-rtr-01-sa-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-SA_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-sa-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-SA_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-sa-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-SA_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-AW ----------
  {
    id: "product-image-rtr-01-aw-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-AW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-aw-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-AW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-aw-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-AW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-RW ----------
  {
    id: "product-image-rtr-01-rw-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-RW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-RW_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-rw-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-RW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-RW_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-rw-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-RW",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-RW_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-RB ----------
  {
    id: "product-image-rtr-01-rb-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-RB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-RB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-rb-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-RB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-RB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-rb-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-RB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-RB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- RTR-01-AB ----------
  {
    id: "product-image-rtr-01-ab-rgb-png",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-AB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-ab-rgb-jpg",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-AB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-rtr-01-ab-cmyk-tif",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-AB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- BAT-01 ----------
  {
    id: "product-image-bat-01-rgb-png",
    type: "Product image",
    product: "BAT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/BAT-01/plejd_product_image_BAT-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-bat-01-rgb-jpg",
    type: "Product image",
    product: "BAT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/BAT-01/plejd_product_image_BAT-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-bat-01-cmyk-tif",
    type: "Product image",
    product: "BAT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/BAT-01/plejd_product_image_BAT-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- MNT-01 ----------
  {
    id: "product-image-mnt-01-rgb-png",
    type: "Product image",
    product: "MNT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/MNT-01/plejd_product_image_MNT-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-mnt-01-rgb-jpg",
    type: "Product image",
    product: "MNT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/MNT-01/plejd_product_image_MNT-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-mnt-01-cmyk-tif",
    type: "Product image",
    product: "MNT-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/MNT-01/plejd_product_image_MNT-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- MNT-02 ----------
  {
    id: "product-image-mnt-02-rgb-png",
    type: "Product image",
    product: "MNT-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/MNT-02/plejd_product_image_MNT-02_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-mnt-02-rgb-jpg",
    type: "Product image",
    product: "MNT-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/MNT-02/plejd_product_image_MNT-02_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-mnt-02-cmyk-tif",
    type: "Product image",
    product: "MNT-02",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/MNT-02/plejd_product_image_MNT-02_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- SPR-01 ----------
  {
    id: "product-image-spr-01-rgb-png",
    type: "Product image",
    product: "SPR-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/SPR-01/plejd_product_image_SPR-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-spr-01-rgb-jpg",
    type: "Product image",
    product: "SPR-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/SPR-01/plejd_product_image_SPR-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-spr-01-cmyk-tif",
    type: "Product image",
    product: "SPR-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/SPR-01/plejd_product_image_SPR-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- SPR-01-BL ----------
  {
    id: "product-image-spr-01-bl-rgb-png",
    type: "Product image",
    product: "SPR-01",
    language: "",
    title: "SPR-01-BL",
    file: "https://brand.plejd.com/downloads/product-image/SPR-01/plejd_product_image_SPR-01-BL_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-spr-01-bl-rgb-jpg",
    type: "Product image",
    product: "SPR-01",
    language: "",
    title: "SPR-01-BL",
    file: "https://brand.plejd.com/downloads/product-image/SPR-01/plejd_product_image_SPR-01-BL_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-spr-01-bl-cmyk-tif",
    type: "Product image",
    product: "SPR-01",
    language: "",
    title: "SPR-01-BL",
    file: "https://brand.plejd.com/downloads/product-image/SPR-01/plejd_product_image_SPR-01-BL_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- DWN-01-W ----------
  {
    id: "product-image-dwn-01-w-rgb-png",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-W",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-01-w-rgb-jpg",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-W",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-01-w-cmyk-tif",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-W",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- DWN-01-B ----------
  {
    id: "product-image-dwn-01-b-rgb-png",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-B",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-01-b-rgb-jpg",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-B",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-01-b-cmyk-tif",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-B",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- DWN-02-W ----------
  {
    id: "product-image-dwn-02-w-rgb-png",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-W",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-02-w-rgb-jpg",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-W",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-02-w-cmyk-tif",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-W",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- DWN-02-B ----------
  {
    id: "product-image-dwn-02-b-rgb-png",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-B",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-02-b-rgb-jpg",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-B",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-dwn-02-b-cmyk-tif",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-B",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- LST-01 ----------
  {
    id: "product-image-lst-01-rgb-png",
    type: "Product image",
    product: "LST-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LST-01/plejd_product_image_LST-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-lst-01-rgb-jpg",
    type: "Product image",
    product: "LST-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LST-01/plejd_product_image_LST-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-lst-01-cmyk-tif",
    type: "Product image",
    product: "LST-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/LST-01/plejd_product_image_LST-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WMS-01 ----------
  {
    id: "product-image-wms-01-rgb-png",
    type: "Product image",
    product: "WMS-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/WMS-01/plejd_product_image_WMS-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-wms-01-rgb-jpg",
    type: "Product image",
    product: "WMS-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/WMS-01/plejd_product_image_WMS-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-wms-01-cmyk-tif",
    type: "Product image",
    product: "WMS-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/WMS-01/plejd_product_image_WMS-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- OUT-01-W ----------
  {
    id: "product-image-out-01-w-rgb-png",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-W",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-out-01-w-rgb-jpg",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-W",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-out-01-w-cmyk-tif",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-W",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- OUT-01-B ----------
  {
    id: "product-image-out-01-b-rgb-png",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-B",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-out-01-b-rgb-jpg",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-B",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-out-01-b-cmyk-tif",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-B",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- OUT-01-G ----------
  {
    id: "product-image-out-01-g-rgb-png",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-G",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-G_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-out-01-g-rgb-jpg",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-G",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-G_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-out-01-g-cmyk-tif",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-G",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-G_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- JAL-01 ----------
  {
    id: "product-image-jal-01-rgb-png",
    type: "Product image",
    product: "JAL-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/JAL-01/plejd_product_image_JAL-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-jal-01-rgb-jpg",
    type: "Product image",
    product: "JAL-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/JAL-01/plejd_product_image_JAL-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-jal-01-cmyk-tif",
    type: "Product image",
    product: "JAL-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/JAL-01/plejd_product_image_JAL-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- WIN-01 ----------
  {
    id: "product-image-win-01-rgb-png",
    type: "Product image",
    product: "WIN-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/WIN-01/plejd_product_image_WIN-01_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-win-01-rgb-jpg",
    type: "Product image",
    product: "WIN-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/WIN-01/plejd_product_image_WIN-01_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-win-01-cmyk-tif",
    type: "Product image",
    product: "WIN-01",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/WIN-01/plejd_product_image_WIN-01_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- CCL-01 ----------
  {
    id: "product-image-ccl-01-w-rgb-png",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-W",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-w-rgb-jpg",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-W",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-w-cmyk-tif",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-W",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-s-w-rgb-png",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-S-W",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-S-W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-s-w-rgb-jpg",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-S-W",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-S-W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-s-w-cmyk-tif",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-S-W",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-S-W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-b-rgb-png",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-B",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-b-rgb-jpg",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-B",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-b-cmyk-tif",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-B",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-s-b-rgb-png",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-S-B",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-S-B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-s-b-rgb-jpg",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-S-B",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-S-B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-ccl-01-s-b-cmyk-tif",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-S-B",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-S-B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-03 ----------
  {
    id: "product-image-vri-03-rgb-png",
    type: "Product image",
    product: "VRI-03",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/VRI-03/plejd_product_image_VRI-03_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-03-rgb-jpg",
    type: "Product image",
    product: "VRI-03",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/VRI-03/plejd_product_image_VRI-03_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-03-cmyk-tif",
    type: "Product image",
    product: "VRI-03",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/product-image/VRI-03/plejd_product_image_VRI-03_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- VRI-03 Claws ----------
  {
    id: "product-image-vri-03-claws-rgb-png",
    type: "Product image",
    product: "VRI-03",
    language: "",
    title: "VRI-03 Claws",
    file: "https://brand.plejd.com/downloads/product-image/VRI-03/plejd_product_image_VRI-03_claws_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-vri-03-claws-rgb-jpg",
    type: "Product image",
    product: "VRI-03",
    language: "",
    title: "VRI-03 Claws",
    file: "https://brand.plejd.com/downloads/product-image/VRI-03/plejd_product_image_VRI-03_claws_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-vri-03-claws-cmyk-tif",
    type: "Product image",
    product: "VRI-03",
    language: "",
    title: "VRI-03 Claws",
    file: "https://brand.plejd.com/downloads/product-image/VRI-03/plejd_product_image_VRI-03_claws_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-SW EN ----------
  {
    id: "product-image-trm-01-sw-en-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_EN_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sw-en-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_EN_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sw-en-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_EN_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-SA EN ----------
  {
    id: "product-image-trm-01-sa-en-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_EN_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sa-en-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_EN_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sa-en-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_EN_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-EW EN ----------
  {
    id: "product-image-trm-01-ew-en-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_EN_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ew-en-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_EN_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ew-en-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_EN_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-EB EN ----------
  {
    id: "product-image-trm-01-eb-en-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_EN_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-eb-en-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_EN_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-eb-en-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_EN_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-AW EN ----------
  {
    id: "product-image-trm-01-aw-en-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_EN_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-aw-en-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_EN_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-aw-en-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_EN_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-AB EN ----------
  {
    id: "product-image-trm-01-ab-en-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_EN_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ab-en-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_EN_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ab-en-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "English",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_EN_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-SW SV ----------
  {
    id: "product-image-trm-01-sw-sv-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_SV_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sw-sv-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_SV_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sw-sv-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_SV_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-SA SV ----------
  {
    id: "product-image-trm-01-sa-sv-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_SV_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sa-sv-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_SV_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sa-sv-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_SV_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-EW SV ----------
  {
    id: "product-image-trm-01-ew-sv-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_SV_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ew-sv-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_SV_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ew-sv-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_SV_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-EB SV ----------
  {
    id: "product-image-trm-01-eb-sv-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_SV_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-eb-sv-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_SV_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-eb-sv-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_SV_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-AW SV ----------
  {
    id: "product-image-trm-01-aw-sv-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_SV_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-aw-sv-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_SV_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-aw-sv-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_SV_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-AB SV ----------
  {
    id: "product-image-trm-01-ab-sv-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_SV_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ab-sv-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_SV_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ab-sv-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Swedish",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_SV_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-SW NB ----------
  {
    id: "product-image-trm-01-sw-nb-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_NB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sw-nb-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_NB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sw-nb-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-SW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SW_NB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-SA NB ----------
  {
    id: "product-image-trm-01-sa-nb-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_NB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sa-nb-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_NB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-sa-nb-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-SA",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-SA_NB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-EW NB ----------
  {
    id: "product-image-trm-01-ew-nb-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_NB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ew-nb-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_NB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ew-nb-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-EW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EW_NB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-EB NB ----------
  {
    id: "product-image-trm-01-eb-nb-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_NB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-eb-nb-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_NB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-eb-nb-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-EB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-EB_NB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-AW NB ----------
  {
    id: "product-image-trm-01-aw-nb-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_NB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-aw-nb-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_NB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-aw-nb-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-AW",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AW_NB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- TRM-01-AB NB ----------
  {
    id: "product-image-trm-01-ab-nb-rgb-png",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_NB_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ab-nb-rgb-jpg",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_NB_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-trm-01-ab-nb-cmyk-tif",
    type: "Product image",
    product: "TRM-01",
    language: "Norwegian",
    title: "TRM-01-AB",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01-AB_NB_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // -------------------- Ambient & Action --------------------
  // ---------- OUT-01 ----------
  {
    id: "product-image-out-01-w-ambient",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-W Ambient",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-W_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-out-01-b-ambient",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-B Ambient",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-B_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-out-01-g-ambient",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01-G Ambient",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01-G_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // ---------- WMS-01 ----------
  {
    id: "product-image-wms-01-ambient",
    type: "Product image",
    product: "WMS-01",
    language: "",
    title: "WMS-01 Ambient",
    file: "https://brand.plejd.com/downloads/product-image/WMS-01/plejd_product_image_WMS-01_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // ---------- LST-01 ----------
  {
    id: "product-image-lst-01-ambient",
    type: "Product image",
    product: "LST-01",
    language: "",
    title: "LST-01 Ambient",
    file: "https://brand.plejd.com/downloads/product-image/LST-01/plejd_product_image_LST-01_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // ---------- WIN-01 ----------
  {
    id: "product-image-win-01-action",
    type: "Product image",
    product: "WIN-01",
    language: "",
    title: "WIN-01 Action",
    file: "https://brand.plejd.com/downloads/product-image/WIN-01/plejd_product_image_WIN-01_action_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // ---------- JAL-01 ----------
  {
    id: "product-image-jal-01-action",
    type: "Product image",
    product: "JAL-01",
    language: "",
    title: "JAL-01 Action",
    file: "https://brand.plejd.com/downloads/product-image/JAL-01/plejd_product_image_JAL-01_action_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // ---------- CCL-01 ----------
  {
    id: "product-image-ccl-01-w-ambient",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-W Ambient",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-W_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-ccl-01-b-ambient",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01-B Ambient",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01-B_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // ---------- DWN-01 ----------
  {
    id: "product-image-dwn-01-w-ambient",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-W Ambient",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-W_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-dwn-01-w-ambient-2",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-W Ambient 2",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-W_ambient_2_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-dwn-01-w-action",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-W Action",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-W_action_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-dwn-01-b-action",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01-B Action",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01-B_action_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // ---------- DWN-02 ----------
  {
    id: "product-image-dwn-02-w-ambient",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-W Ambient",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-W_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-dwn-02-w-ambient-2",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-W Ambient 2",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-W_ambient_2_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-dwn-02-w-action",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-W Action",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-W_action_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-dwn-02-b-action",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02-B Action",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02-B_action_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  // -------------------- Dimensions --------------------
  // ---------- Puck 1 ----------
  {
    id: "product-image-puck-1-dimensions-dim-01",
    type: "Product image",
    product: "DIM-01",
    language: "",
    title: "DIM-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-1_dimensions.pdf",
    details: "PDF",
  },
  // ---------- Puck 2 ----------
  {
    id: "product-image-puck-2-dimensions-dim-01-2p",
    type: "Product image",
    product: "DIM-01-2P",
    language: "",
    title: "DIM-01-2P Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-dim-02",
    type: "Product image",
    product: "DIM-02",
    language: "",
    title: "DIM-02 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-ctr-01",
    type: "Product image",
    product: "CTR-01",
    language: "",
    title: "CTR-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-led-10",
    type: "Product image",
    product: "LED-10",
    language: "",
    title: "LED-10 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-rel-02",
    type: "Product image",
    product: "REL-02",
    language: "",
    title: "REL-02 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-rel-01-2p",
    type: "Product image",
    product: "REL-01-2P",
    language: "",
    title: "REL-01-2P Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-dal-01",
    type: "Product image",
    product: "DAL-01",
    language: "",
    title: "DAL-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-ext-01",
    type: "Product image",
    product: "EXT-01",
    language: "",
    title: "EXT-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-puck-2-dimensions-jal-01",
    type: "Product image",
    product: "JAL-01",
    language: "",
    title: "JAL-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/generic/plejd_product_image_puck-2_dimensions.pdf",
    details: "PDF",
  },
  // ---------- Other ----------
  {
    id: "product-image-vri-01-dimensions",
    type: "Product image",
    product: "VRI-01",
    language: "",
    title: "VRI-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-vri-02-dimensions",
    type: "Product image",
    product: "VRI-02",
    language: "",
    title: "VRI-02 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/VRI-02/plejd_product_image_VRI-02_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-led-75-dimensions",
    type: "Product image",
    product: "LED-75",
    language: "",
    title: "LED-75 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/LED-75/plejd_product_image_LED-75_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-gwy-01-dimensions",
    type: "Product image",
    product: "GWY-01",
    language: "",
    title: "GWY-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/GWY-01/plejd_product_image_GWY-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-wph-01-dimensions",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-wph-01-a-dimensions",
    type: "Product image",
    product: "WPH-01",
    language: "",
    title: "WPH-01-A Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/WPH-01/plejd_product_image_WPH-01-A_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-wrt-01-dimensions",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-wrt-01-r-dimensions",
    type: "Product image",
    product: "WRT-01",
    language: "",
    title: "WRT-01-R Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/WRT-01/plejd_product_image_WRT-01-R_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-rtr-01-dimensions",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-rtr-01-r-dimensions",
    type: "Product image",
    product: "RTR-01",
    language: "",
    title: "RTR-01-R Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/RTR-01/plejd_product_image_RTR-01-R_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-bat-01-dimensions",
    type: "Product image",
    product: "BAT-01",
    language: "",
    title: "BAT-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/BAT-01/plejd_product_image_BAT-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-mnt-01-dimensions",
    type: "Product image",
    product: "MNT-01",
    language: "",
    title: "MNT-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/MNT-01/plejd_product_image_MNT-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-mnt-02-dimensions",
    type: "Product image",
    product: "MNT-02",
    language: "",
    title: "MNT-02 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/MNT-02/plejd_product_image_MNT-02_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-spr-01-dimensions",
    type: "Product image",
    product: "SPR-01",
    language: "",
    title: "SPR-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/SPR-01/plejd_product_image_SPR-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-dwn-01-dimensions",
    type: "Product image",
    product: "DWN-01",
    language: "",
    title: "DWN-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/DWN-01/plejd_product_image_DWN-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-dwn-02-dimensions",
    type: "Product image",
    product: "DWN-02",
    language: "",
    title: "DWN-02 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/DWN-02/plejd_product_image_DWN-02_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-lst-01-dimensions",
    type: "Product image",
    product: "LST-01",
    language: "",
    title: "LST-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/LST-01/plejd_product_image_LST-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-out-01-dimensions",
    type: "Product image",
    product: "OUT-01",
    language: "",
    title: "OUT-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/OUT-01/plejd_product_image_OUT-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-wms-01-dimensions",
    type: "Product image",
    product: "WMS-01",
    language: "",
    title: "WMS-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/WMS-01/plejd_product_image_WMS-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-win-01-dimensions",
    type: "Product image",
    product: "WIN-01",
    language: "",
    title: "WIN-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/WIN-01/plejd_product_image_WIN-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-ccl-01-dimensions",
    type: "Product image",
    product: "CCL-01",
    language: "",
    title: "CCL-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/CCL-01/plejd_product_image_CCL-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-trm-01-dimensions",
    type: "Product image",
    product: "TRM-01",
    language: "",
    title: "TRM-01 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/TRM-01/plejd_product_image_TRM-01_dimensions.pdf",
    details: "PDF",
  },
  {
    id: "product-image-vri-03-dimensions",
    type: "Product image",
    product: "VRI-03",
    language: "",
    title: "VRI-03 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/VRI-03/plejd_product_image_VRI-03_dimensions.pdf",
    details: "PDF",
  },
  // -------------------- Press images --------------------
  // ---------- Guidelines ----------
  {
    id: "brand-guidelines-en",
    type: "Press image",
    product: "Brand Guidelines",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/information/plejd_brand_guidelines_EN.pdf",
    details: "PDF",
  },
  // ---------- Logo ----------
  {
    id: "logo-blue-rgb-eps",
    type: "Press image",
    product: "Plejd logo in blue",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_blue_RGB.eps",
    details: "Digital, RGB, EPS, Vector",
  },
  {
    id: "logo-blue-rgb-png",
    type: "Press image",
    product: "Plejd logo in blue",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_blue_RGB.png",
    details: "Digital, RGB, PNG",
  },
  {
    id: "logo-blue-cmyk-eps",
    type: "Press image",
    product: "Plejd logo in blue",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_blue_CMYK.eps",
    details: "Print, CMYK, EPS, Vector",
  },
  {
    id: "logo-blue-cmyk-tif",
    type: "Press image",
    product: "Plejd logo in blue",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_blue_CMYK.tif",
    details: "Print, CMYK, TIF",
  },
  {
    id: "logo-white-rgb-eps",
    type: "Press image",
    product: "Plejd logo in white",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_white_RGB.eps",
    details: "Digital, RGB, EPS, Vector",
  },
  {
    id: "logo-white-rgb-png",
    type: "Press image",
    product: "Plejd logo in white",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_white_RGB.png",
    details: "Digital, RGB, PNG",
  },
  {
    id: "logo-white-cmyk-eps",
    type: "Press image",
    product: "Plejd logo in white",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_white_CMYK.eps",
    details: "Print, CMYK, EPS, Vector",
  },
  {
    id: "logo-white-cmyk-tif",
    type: "Press image",
    product: "Plejd logo in white",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_white_CMYK.tif",
    details: "Print, CMYK, TIF",
  },
  {
    id: "logo-black-rgb-eps",
    type: "Press image",
    product: "Plejd logo in black",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_black_RGB.eps",
    details: "Digital, RGB, EPS, Vector",
  },
  {
    id: "logo-black-rgb-png",
    type: "Press image",
    product: "Plejd logo in black",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_black_RGB.png",
    details: "Digital, RGB, PNG",
  },
  {
    id: "logo-black-cmyk-eps",
    type: "Press image",
    product: "Plejd logo in black",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_black_CMYK.eps",
    details: "Print, CMYK, EPS, Vector",
  },
  {
    id: "logo-black-cmyk-tif",
    type: "Press image",
    product: "Plejd logo in black",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/logo/plejd_logo_black_CMYK.tif",
    details: "Print, CMYK, TIF",
  },
  // ---------- Image kit ----------
  {
    id: "press-image-image-kit",
    type: "Press image",
    product: "Image kit",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/image-kit/plejd_image_kit.zip",
    details: "Digital, ZIP, RGB, JPG, 3000x1680 px",
  },
  // ---------- People ----------
  {
    id: "press-image-babak-esfahani",
    type: "Press image",
    product: "Press image Babak Esfahani",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/press-image/plejd_press_image_babak_esfahani.png",
    details: "Digital, RGB, PNG, 1000x1500 px",
  },
  // -------------------- Product sheets --------------------
  // ---------- English ----------
  {
    id: "0127",
    type: "Product sheet",
    product: "DIM-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0128",
    type: "Product sheet",
    product: "DIM-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0081",
    type: "Product sheet",
    product: "CTR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0130",
    type: "Product sheet",
    product: "LED-10",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_EN.pdf",
    details: "PDF",
  },
  {
    id: "0129",
    type: "Product sheet",
    product: "REL-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0131",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_EN.pdf",
    details: "PDF",
  },
  {
    id: "0111",
    type: "Product sheet",
    product: "DAL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0273",
    type: "Product sheet",
    product: "EXT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0185",
    type: "Product sheet",
    product: "LED-75",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_EN.pdf",
    details: "PDF",
  },
  {
    id: "0065",
    type: "Product sheet",
    product: "GWY-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0134",
    type: "Product sheet",
    product: "WPH-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0050",
    type: "Product sheet",
    product: "BAT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0132",
    type: "Product sheet",
    product: "MNT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0133",
    type: "Product sheet",
    product: "MNT-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0179",
    type: "Product sheet",
    product: "SPR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0188",
    type: "Product sheet",
    product: "DWN-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-01/plejd_product_sheet_DWN-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0459",
    type: "Product sheet",
    product: "DWN-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-02/plejd_product_sheet_DWN-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0462",
    type: "Product sheet",
    product: "LST-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0465",
    type: "Product sheet",
    product: "OUT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-01/plejd_product_sheet_OUT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0490",
    type: "Product sheet",
    product: "WMS-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0529",
    type: "Product sheet",
    product: "JAL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0521",
    type: "Product sheet",
    product: "WIN-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0512",
    type: "Product sheet",
    product: "CCL-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CCL-01/plejd_product_sheet_CCL-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0135",
    type: "Product sheet",
    product: "RTR-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/RTR-01/plejd_product_sheet_RTR-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0103",
    type: "Product sheet",
    product: "WRT-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WRT-01/plejd_product_sheet_WRT-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0412",
    type: "Product sheet",
    product: "VRI-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-01/plejd_product_sheet_VRI-01_EN.pdf",
    details: "PDF",
  },
  {
    id: "0136",
    type: "Product sheet",
    product: "VRI-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-02/plejd_product_sheet_VRI-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0137",
    type: "Product sheet",
    product: "DIM-01-2P",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01-2P/plejd_product_sheet_DIM-01-2P_EN.pdf",
    details: "PDF",
  },
  {
    id: "0589",
    type: "Product sheet",
    product: "VRI-03",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-03/plejd_product_sheet_VRI-03_EN.pdf",
    details: "PDF",
  },
  {
    id: "0585",
    type: "Product sheet",
    product: "TRM-01",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/TRM-01/plejd_product_sheet_TRM-01_EN.pdf",
    details: "PDF",
  },
  // ---------- Swedish ----------
  {
    id: "0004",
    type: "Product sheet",
    product: "DIM-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0005",
    type: "Product sheet",
    product: "DIM-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0003",
    type: "Product sheet",
    product: "CTR-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0009",
    type: "Product sheet",
    product: "LED-10",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_SV.pdf",
    details: "PDF",
  },
  {
    id: "0049",
    type: "Product sheet",
    product: "REL-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0176",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_SV.pdf",
    details: "PDF",
  },
  {
    id: "0126",
    type: "Product sheet",
    product: "DAL-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0274",
    type: "Product sheet",
    product: "EXT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0405",
    type: "Product sheet",
    product: "LED-75",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_SV.pdf",
    details: "PDF",
  },
  {
    id: "0008",
    type: "Product sheet",
    product: "GWY-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0016",
    type: "Product sheet",
    product: "WPH-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0038",
    type: "Product sheet",
    product: "WRT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WRT-01/plejd_product_sheet_WRT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0012",
    type: "Product sheet",
    product: "RTR-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/RTR-01/plejd_product_sheet_RTR-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0002",
    type: "Product sheet",
    product: "BAT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0010",
    type: "Product sheet",
    product: "MNT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0041",
    type: "Product sheet",
    product: "MNT-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0048",
    type: "Product sheet",
    product: "SPR-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0187",
    type: "Product sheet",
    product: "DWN-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-01/plejd_product_sheet_DWN-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0458",
    type: "Product sheet",
    product: "DWN-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-02/plejd_product_sheet_DWN-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0461",
    type: "Product sheet",
    product: "LST-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0464",
    type: "Product sheet",
    product: "OUT-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-01/plejd_product_sheet_OUT-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0497",
    type: "Product sheet",
    product: "WMS-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0522",
    type: "Product sheet",
    product: "JAL-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0513",
    type: "Product sheet",
    product: "WIN-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0510",
    type: "Product sheet",
    product: "CCL-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CCL-01/plejd_product_sheet_CCL-01_SV.pdf",
    details: "PDF",
  },
  {
    id: "0586",
    type: "Product sheet",
    product: "TRM-01",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/TRM-01/plejd_product_sheet_TRM-01_SV.pdf",
    details: "PDF",
  },
  // ---------- Swedish (Finland) ----------
  {
    id: "0164",
    type: "Product sheet",
    product: "DIM-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0165",
    type: "Product sheet",
    product: "DIM-02",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0166",
    type: "Product sheet",
    product: "CTR-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0168",
    type: "Product sheet",
    product: "LED-10",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0167",
    type: "Product sheet",
    product: "REL-02",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0186",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0170",
    type: "Product sheet",
    product: "DAL-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0422",
    type: "Product sheet",
    product: "EXT-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0411",
    type: "Product sheet",
    product: "VRI-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-01/plejd_product_sheet_VRI-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0407",
    type: "Product sheet",
    product: "LED-75",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0169",
    type: "Product sheet",
    product: "GWY-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0143",
    type: "Product sheet",
    product: "WPH-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0145",
    type: "Product sheet",
    product: "WRT-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WRT-01/plejd_product_sheet_WRT-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0144",
    type: "Product sheet",
    product: "RTR-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/RTR-01/plejd_product_sheet_RTR-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0171",
    type: "Product sheet",
    product: "BAT-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0172",
    type: "Product sheet",
    product: "MNT-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0173",
    type: "Product sheet",
    product: "MNT-02",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0178",
    type: "Product sheet",
    product: "SPR-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0483",
    type: "Product sheet",
    product: "DWN-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-01/plejd_product_sheet_DWN-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0484",
    type: "Product sheet",
    product: "DWN-02",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-02/plejd_product_sheet_DWN-02_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0485",
    type: "Product sheet",
    product: "LST-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0486",
    type: "Product sheet",
    product: "OUT-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-01/plejd_product_sheet_OUT-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0574",
    type: "Product sheet",
    product: "WIN-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0577",
    type: "Product sheet",
    product: "WMS-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0573",
    type: "Product sheet",
    product: "JAL-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_SV_FI.pdf",
    details: "PDF",
  },
  {
    id: "0597",
    type: "Product sheet",
    product: "CCL-01",
    language: "Swedish (Finland)",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CCL-01/plejd_product_sheet_CCL-01_SV_FI.pdf",
    details: "PDF",
  },
  // ---------- Norwegian ----------
  {
    id: "0066",
    type: "Product sheet",
    product: "DIM-01-2P",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01-2P/plejd_product_sheet_DIM-01-2P_NB.pdf",
    details: "PDF",
  },
  {
    id: "0057",
    type: "Product sheet",
    product: "DIM-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0078",
    type: "Product sheet",
    product: "LED-10",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_NB.pdf",
    details: "PDF",
  },
  {
    id: "0077",
    type: "Product sheet",
    product: "CTR-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0079",
    type: "Product sheet",
    product: "REL-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0046",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_NB.pdf",
    details: "PDF",
  },
  {
    id: "0125",
    type: "Product sheet",
    product: "DAL-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0275",
    type: "Product sheet",
    product: "EXT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0093",
    type: "Product sheet",
    product: "VRI-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-02/plejd_product_sheet_VRI-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0408",
    type: "Product sheet",
    product: "LED-75",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_NB.pdf",
    details: "PDF",
  },
  {
    id: "0060",
    type: "Product sheet",
    product: "GWY-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0058",
    type: "Product sheet",
    product: "WPH-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0055",
    type: "Product sheet",
    product: "WRT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WRT-01/plejd_product_sheet_WRT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0124",
    type: "Product sheet",
    product: "RTR-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/RTR-01/plejd_product_sheet_RTR-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0071",
    type: "Product sheet",
    product: "BAT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0053",
    type: "Product sheet",
    product: "MNT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0080",
    type: "Product sheet",
    product: "MNT-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0182",
    type: "Product sheet",
    product: "SPR-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0191",
    type: "Product sheet",
    product: "DWN-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-01/plejd_product_sheet_DWN-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0460",
    type: "Product sheet",
    product: "DWN-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-02/plejd_product_sheet_DWN-02_NB.pdf",
    details: "PDF",
  },
  {
    id: "0463",
    type: "Product sheet",
    product: "LST-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0466",
    type: "Product sheet",
    product: "OUT-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-01/plejd_product_sheet_OUT-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0499",
    type: "Product sheet",
    product: "WMS-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0575",
    type: "Product sheet",
    product: "JAL-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0576",
    type: "Product sheet",
    product: "WIN-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0511",
    type: "Product sheet",
    product: "CCL-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CCL-01/plejd_product_sheet_CCL-01_NB.pdf",
    details: "PDF",
  },
  {
    id: "0587",
    type: "Product sheet",
    product: "TRM-01",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/TRM-01/plejd_product_sheet_TRM-01_NB.pdf",
    details: "PDF",
  },
  // ---------- Finnish ----------
  {
    id: "0033",
    type: "Product sheet",
    product: "DIM-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0054",
    type: "Product sheet",
    product: "DIM-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0108",
    type: "Product sheet",
    product: "LED-10",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_FI.pdf",
    details: "PDF",
  },
  {
    id: "0043",
    type: "Product sheet",
    product: "CTR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0101",
    type: "Product sheet",
    product: "REL-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0184",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_FI.pdf",
    details: "PDF",
  },
  {
    id: "0162",
    type: "Product sheet",
    product: "DAL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0276",
    type: "Product sheet",
    product: "EXT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0410",
    type: "Product sheet",
    product: "VRI-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-01/plejd_product_sheet_VRI-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0406",
    type: "Product sheet",
    product: "LED-75",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_FI.pdf",
    details: "PDF",
  },
  {
    id: "0061",
    type: "Product sheet",
    product: "GWY-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0138",
    type: "Product sheet",
    product: "WPH-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0139",
    type: "Product sheet",
    product: "WRT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WRT-01/plejd_product_sheet_WRT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0042",
    type: "Product sheet",
    product: "RTR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/RTR-01/plejd_product_sheet_RTR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0044",
    type: "Product sheet",
    product: "BAT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0045",
    type: "Product sheet",
    product: "MNT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0102",
    type: "Product sheet",
    product: "MNT-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0180",
    type: "Product sheet",
    product: "SPR-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0479",
    type: "Product sheet",
    product: "DWN-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-01/plejd_product_sheet_DWN-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0480",
    type: "Product sheet",
    product: "DWN-02",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-02/plejd_product_sheet_DWN-02_FI.pdf",
    details: "PDF",
  },
  {
    id: "0481",
    type: "Product sheet",
    product: "LST-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0482",
    type: "Product sheet",
    product: "OUT-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-01/plejd_product_sheet_OUT-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0572",
    type: "Product sheet",
    product: "WIN-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0581",
    type: "Product sheet",
    product: "WMS-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0571",
    type: "Product sheet",
    product: "JAL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_FI.pdf",
    details: "PDF",
  },
  {
    id: "0596",
    type: "Product sheet",
    product: "CCL-01",
    language: "Finnish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CCL-01/plejd_product_sheet_CCL-01_FI.pdf",
    details: "PDF",
  },
  // ---------- Dutch ----------
  {
    id: "0148",
    type: "Product sheet",
    product: "DIM-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0149",
    type: "Product sheet",
    product: "DIM-02",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_NL.pdf",
    details: "PDF",
  },
  {
    id: "0150",
    type: "Product sheet",
    product: "CTR-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0152",
    type: "Product sheet",
    product: "LED-10",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_NL.pdf",
    details: "PDF",
  },
  {
    id: "0151",
    type: "Product sheet",
    product: "REL-02",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_NL.pdf",
    details: "PDF",
  },
  {
    id: "0153",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_NL.pdf",
    details: "PDF",
  },
  {
    id: "0113",
    type: "Product sheet",
    product: "DAL-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0421",
    type: "Product sheet",
    product: "EXT-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0409",
    type: "Product sheet",
    product: "LED-75",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_NL.pdf",
    details: "PDF",
  },
  {
    id: "0154",
    type: "Product sheet",
    product: "GWY-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0146",
    type: "Product sheet",
    product: "WPH-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0155",
    type: "Product sheet",
    product: "BAT-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0156",
    type: "Product sheet",
    product: "MNT-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0157",
    type: "Product sheet",
    product: "MNT-02",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_NL.pdf",
    details: "PDF",
  },
  {
    id: "0177",
    type: "Product sheet",
    product: "SPR-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0493",
    type: "Product sheet",
    product: "DWN-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-01/plejd_product_sheet_DWN-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0494",
    type: "Product sheet",
    product: "DWN-02",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-02/plejd_product_sheet_DWN-02_NL.pdf",
    details: "PDF",
  },
  {
    id: "0495",
    type: "Product sheet",
    product: "LST-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0496",
    type: "Product sheet",
    product: "OUT-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-01/plejd_product_sheet_OUT-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0565",
    type: "Product sheet",
    product: "JAL-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0566",
    type: "Product sheet",
    product: "WIN-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0583",
    type: "Product sheet",
    product: "WMS-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_NL.pdf",
    details: "PDF",
  },
  {
    id: "0590",
    type: "Product sheet",
    product: "VRI-03",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-03/plejd_product_sheet_VRI-03_NL.pdf",
    details: "PDF",
  },
  {
    id: "0598",
    type: "Product sheet",
    product: "CCL-01",
    language: "Dutch",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CCL-01/plejd_product_sheet_CCL-01_NL.pdf",
    details: "PDF",
  },
  // ---------- German ----------
  {
    id: "0196",
    type: "Product sheet",
    product: "DIM-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0197",
    type: "Product sheet",
    product: "DIM-02",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_DE.pdf",
    details: "PDF",
  },
  {
    id: "0194",
    type: "Product sheet",
    product: "CTR-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0199",
    type: "Product sheet",
    product: "LED-10",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_DE.pdf",
    details: "PDF",
  },
  {
    id: "0203",
    type: "Product sheet",
    product: "REL-02",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_DE.pdf",
    details: "PDF",
  },
  {
    id: "0202",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_DE.pdf",
    details: "PDF",
  },
  {
    id: "0195",
    type: "Product sheet",
    product: "DAL-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0277",
    type: "Product sheet",
    product: "EXT-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0424",
    type: "Product sheet",
    product: "LED-75",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_DE.pdf",
    details: "PDF",
  },
  {
    id: "0198",
    type: "Product sheet",
    product: "GWY-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0205",
    type: "Product sheet",
    product: "WPH-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0193",
    type: "Product sheet",
    product: "BAT-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0200",
    type: "Product sheet",
    product: "MNT-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0201",
    type: "Product sheet",
    product: "MNT-02",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_DE.pdf",
    details: "PDF",
  },
  {
    id: "0503",
    type: "Product sheet",
    product: "LST-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0567",
    type: "Product sheet",
    product: "JAL-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0568",
    type: "Product sheet",
    product: "WIN-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0582",
    type: "Product sheet",
    product: "WMS-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0204",
    type: "Product sheet",
    product: "SPR-01",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_DE.pdf",
    details: "PDF",
  },
  {
    id: "0588",
    type: "Product sheet",
    product: "VRI-03",
    language: "German",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/VRI-03/plejd_product_sheet_VRI-03_DE.pdf",
    details: "PDF",
  },
  // ---------- Spanish ----------
  {
    id: "0209",
    type: "Product sheet",
    product: "DIM-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0210",
    type: "Product sheet",
    product: "DIM-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0207",
    type: "Product sheet",
    product: "CTR-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0212",
    type: "Product sheet",
    product: "LED-10",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_ES.pdf",
    details: "PDF",
  },
  {
    id: "0216",
    type: "Product sheet",
    product: "REL-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0215",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_ES.pdf",
    details: "PDF",
  },
  {
    id: "0208",
    type: "Product sheet",
    product: "DAL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0278",
    type: "Product sheet",
    product: "EXT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0423",
    type: "Product sheet",
    product: "LED-75",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_ES.pdf",
    details: "PDF",
  },
  {
    id: "0211",
    type: "Product sheet",
    product: "GWY-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0218",
    type: "Product sheet",
    product: "WPH-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0206",
    type: "Product sheet",
    product: "BAT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0213",
    type: "Product sheet",
    product: "MNT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0214",
    type: "Product sheet",
    product: "MNT-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0217",
    type: "Product sheet",
    product: "SPR-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/SPR-01/plejd_product_sheet_SPR-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0507",
    type: "Product sheet",
    product: "DWN-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-01/plejd_product_sheet_DWN-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0508",
    type: "Product sheet",
    product: "DWN-02",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DWN-02/plejd_product_sheet_DWN-02_ES.pdf",
    details: "PDF",
  },
  {
    id: "0506",
    type: "Product sheet",
    product: "LST-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0561",
    type: "Product sheet",
    product: "JAL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0562",
    type: "Product sheet",
    product: "WIN-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0578",
    type: "Product sheet",
    product: "WMS-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0584",
    type: "Product sheet",
    product: "OUT-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-01/plejd_product_sheet_OUT-01_ES.pdf",
    details: "PDF",
  },
  {
    id: "0599",
    type: "Product sheet",
    product: "CCL-01",
    language: "Spanish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CCL-01/plejd_product_sheet_CCL-01_ES.pdf",
    details: "PDF",
  },
  // ---------- French ----------
  {
    id: "0235",
    type: "Product sheet",
    product: "DIM-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0236",
    type: "Product sheet",
    product: "DIM-02",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_FR.pdf",
    details: "PDF",
  },
  {
    id: "0233",
    type: "Product sheet",
    product: "CTR-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0238",
    type: "Product sheet",
    product: "LED-10",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_FR.pdf",
    details: "PDF",
  },
  {
    id: "0242",
    type: "Product sheet",
    product: "REL-02",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_FR.pdf",
    details: "PDF",
  },
  {
    id: "0241",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_FR.pdf",
    details: "PDF",
  },
  {
    id: "0234",
    type: "Product sheet",
    product: "DAL-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0280",
    type: "Product sheet",
    product: "EXT-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0443",
    type: "Product sheet",
    product: "LED-75",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_FR.pdf",
    details: "PDF",
  },
  {
    id: "0237",
    type: "Product sheet",
    product: "GWY-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0232",
    type: "Product sheet",
    product: "BAT-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0239",
    type: "Product sheet",
    product: "MNT-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0240",
    type: "Product sheet",
    product: "MNT-02",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_FR.pdf",
    details: "PDF",
  },
  {
    id: "0504",
    type: "Product sheet",
    product: "LST-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0563",
    type: "Product sheet",
    product: "JAL-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0564",
    type: "Product sheet",
    product: "WIN-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_FR.pdf",
    details: "PDF",
  },
  {
    id: "0579",
    type: "Product sheet",
    product: "WMS-01",
    language: "French",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_FR.pdf",
    details: "PDF",
  },
  // ---------- Polish ----------
  {
    id: "0222",
    type: "Product sheet",
    product: "DIM-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0223",
    type: "Product sheet",
    product: "DIM-02",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_PL.pdf",
    details: "PDF",
  },
  {
    id: "0220",
    type: "Product sheet",
    product: "CTR-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0225",
    type: "Product sheet",
    product: "LED-10",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_PL.pdf",
    details: "PDF",
  },
  {
    id: "0229",
    type: "Product sheet",
    product: "REL-02",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_PL.pdf",
    details: "PDF",
  },
  {
    id: "0228",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_PL.pdf",
    details: "PDF",
  },
  {
    id: "0221",
    type: "Product sheet",
    product: "DAL-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0279",
    type: "Product sheet",
    product: "EXT-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0425",
    type: "Product sheet",
    product: "LED-75",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_PL.pdf",
    details: "PDF",
  },
  {
    id: "0224",
    type: "Product sheet",
    product: "GWY-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0231",
    type: "Product sheet",
    product: "WPH-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WPH-01/plejd_product_sheet_WPH-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0219",
    type: "Product sheet",
    product: "BAT-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0226",
    type: "Product sheet",
    product: "MNT-01",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_PL.pdf",
    details: "PDF",
  },
  {
    id: "0227",
    type: "Product sheet",
    product: "MNT-02",
    language: "Polish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_PL.pdf",
    details: "PDF",
  },
  // ---------- Italian ----------
  {
    id: "0431",
    type: "Product sheet",
    product: "DIM-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-01/plejd_product_sheet_DIM-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0432",
    type: "Product sheet",
    product: "DIM-02",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DIM-02/plejd_product_sheet_DIM-02_IT.pdf",
    details: "PDF",
  },
  {
    id: "0435",
    type: "Product sheet",
    product: "CTR-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/CTR-01/plejd_product_sheet_CTR-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0436",
    type: "Product sheet",
    product: "LED-10",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-10/plejd_product_sheet_LED-10_IT.pdf",
    details: "PDF",
  },
  {
    id: "0434",
    type: "Product sheet",
    product: "REL-02",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-02/plejd_product_sheet_REL-02_IT.pdf",
    details: "PDF",
  },
  {
    id: "0433",
    type: "Product sheet",
    product: "REL-01-2P",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/REL-01-2P/plejd_product_sheet_REL-01-2P_IT.pdf",
    details: "PDF",
  },
  {
    id: "0437",
    type: "Product sheet",
    product: "DAL-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/DAL-01/plejd_product_sheet_DAL-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0444",
    type: "Product sheet",
    product: "EXT-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/EXT-01/plejd_product_sheet_EXT-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0438",
    type: "Product sheet",
    product: "LED-75",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LED-75/plejd_product_sheet_LED-75_IT.pdf",
    details: "PDF",
  },
  {
    id: "0439",
    type: "Product sheet",
    product: "GWY-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/GWY-01/plejd_product_sheet_GWY-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0440",
    type: "Product sheet",
    product: "BAT-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/BAT-01/plejd_product_sheet_BAT-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0441",
    type: "Product sheet",
    product: "MNT-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-01/plejd_product_sheet_MNT-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0442",
    type: "Product sheet",
    product: "MNT-02",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/MNT-02/plejd_product_sheet_MNT-02_IT.pdf",
    details: "PDF",
  },
  {
    id: "0505",
    type: "Product sheet",
    product: "LST-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/LST-01/plejd_product_sheet_LST-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0569",
    type: "Product sheet",
    product: "JAL-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/JAL-01/plejd_product_sheet_JAL-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0570",
    type: "Product sheet",
    product: "WIN-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WIN-01/plejd_product_sheet_WIN-01_IT.pdf",
    details: "PDF",
  },
  {
    id: "0580",
    type: "Product sheet",
    product: "WMS-01",
    language: "Italian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/WMS-01/plejd_product_sheet_WMS-01_IT.pdf",
    details: "PDF",
  },
  // -------------------- Licenses --------------------
  {
    id: "compliance-app-licenses-notice",
    type: "Compliance",
    product: "Licens",
    language: "English",
    title: "App licenses notice",
    file: "https://www.plejd.com/licenses/app",
    details: "Website",
  },
  {
    id: "compliance-firmware-licenses-notice",
    type: "Compliance",
    product: "Licens",
    language: "English",
    title: "Firmware licenses notice",
    file: "https://www.plejd.com/licenses/firmware",
    details: "Website",
  },
  {
    id: "compliance-gwy-licenses-notice",
    type: "Compliance",
    product: "Licens",
    language: "English",
    title: "Gateway licenses notice",
    file: "https://www.plejd.com/licenses/gateway",
    details: "Website",
  },
  // -------------------- DIALux --------------------
  {
    id: "dialux-ccl-01-w",
    type: "DIALux",
    product: "CCL-01-W",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_CCL-01-W.ldt",
    details: "LDT",
  },
  {
    id: "dialux-ccl-01-s-w",
    type: "DIALux",
    product: "CCL-01-S-W",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_CCL-01-S-W.ldt",
    details: "LDT",
  },
  {
    id: "dialux-ccl-01-b",
    type: "DIALux",
    product: "CCL-01-B",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_CCL-01-B.ldt",
    details: "LDT",
  },
  {
    id: "dialux-ccl-01-s-b",
    type: "DIALux",
    product: "CCL-01-S-B",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_CCL-01-S-B.ldt",
    details: "LDT",
  },
  {
    id: "dialux-dwn-01-w",
    type: "DIALux",
    product: "DWN-01-W",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_DWN-01-W.ldt",
    details: "LDT",
  },
  {
    id: "dialux-dwn-01-b",
    type: "DIALux",
    product: "DWN-01-B",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_DWN-01-B.ldt",
    details: "LDT",
  },
  {
    id: "dialux-dwn-02-w",
    type: "DIALux",
    product: "DWN-02-W",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_DWN-02-W.ldt",
    details: "LDT",
  },
  {
    id: "dialux-dwn-02-b",
    type: "DIALux",
    product: "DWN-02-B",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_DWN-02-B.ldt",
    details: "LDT",
  },
  {
    id: "dialux-out-01-w",
    type: "DIALux",
    product: "OUT-01-W",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_OUT-01-W.ldt",
    details: "LDT",
  },
  {
    id: "dialux-out-01-b",
    type: "DIALux",
    product: "OUT-01-B",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_OUT-01-B.ldt",
    details: "LDT",
  },
  {
    id: "dialux-out-01-G",
    type: "DIALux",
    product: "OUT-01-G",
    language: "",
    title: "",
    file: "https://brand.plejd.com/downloads/dialux/plejd_dialux_OUT-01-G.ldt",
    details: "LDT",
  },
  // -------------------- END --------------------
];
export default FileCardData;

// -------------------- Unlisted --------------------
// eslint-disable-next-line
const Unlisted = [
  

  {
    id: "0602",
    type: "Manual",
    product: "OUT-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-02/plejd_manual_OUT-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "1178",
    type: "Manual",
    product: "OUT-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-02/plejd_manual_OUT-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "1179",
    type: "Manual",
    product: "OUT-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/manual/OUT-02/plejd_manual_OUT-02_NB.pdf",
    details: "PDF",
  },


  {
    id: "0591",
    type: "Product sheet",
    product: "OUT-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-02/plejd_product_sheet_OUT-02_EN.pdf",
    details: "PDF",
  },
  {
    id: "0592",
    type: "Product sheet",
    product: "OUT-02",
    language: "Swedish",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-02/plejd_product_sheet_OUT-02_SV.pdf",
    details: "PDF",
  },
  {
    id: "0593",
    type: "Product sheet",
    product: "OUT-02",
    language: "Norwegian",
    title: "",
    file: "https://brand.plejd.com/downloads/product-sheet/OUT-02/plejd_product_sheet_OUT-02_NB.pdf",
    details: "PDF",
  },



  {
    id: "com-0092",
    type: "Declaration of conformity",
    product: "OUT-02",
    language: "English",
    title: "",
    file: "https://brand.plejd.com/downloads/doc/OUT-02/plejd_doc_OUT-02_EN.pdf",
    details: "PDF",
  },



  {
    id: "product-image-out-02-dimensions",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02 Dimensions",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02_dimensions.pdf",
    details: "PDF",
  },


  // ---------- OUT-02-W ----------
  {
    id: "product-image-out-02-w-rgb-png",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-W",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-W_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-out-02-w-rgb-jpg",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-W",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-W_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-out-02-w-cmyk-tif",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-W",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-W_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- OUT-02-B ----------
  {
    id: "product-image-out-02-b-rgb-png",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-B",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-B_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-out-02-b-rgb-jpg",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-B",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-B_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-out-02-b-cmyk-tif",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-B",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-B_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },
  // ---------- OUT-02-G ----------
  {
    id: "product-image-out-02-g-rgb-png",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-G",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-G_RGB.png",
    details: "Digital, RGB, PNG, 1200x1200 px",
  },
  {
    id: "product-image-out-02-g-rgb-jpg",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-G",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-G_RGB.jpg",
    details: "Digital, RGB, JPG, 1200x1200 px",
  },
  {
    id: "product-image-out-02-g-cmyk-tif",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-G",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-G_CMYK.tif",
    details: "Print, CMYK, TIF, 1200x1200 px",
  },


  // ---------- OUT-02 ----------
  {
    id: "product-image-out-02-w-ambient",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-W Ambient",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-W_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-out-02-b-ambient",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-B Ambient",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-B_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },
  {
    id: "product-image-out-02-g-ambient",
    type: "Product image",
    product: "OUT-02",
    language: "",
    title: "OUT-02-G Ambient",
    file: "https://brand.plejd.com/downloads/product-image/OUT-02/plejd_product_image_OUT-02-G_ambient_RGB.jpg",
    details: "Digital, RGB, JPG",
  },


];
// -------------------- END --------------------