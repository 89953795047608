import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import FileCardData from '../../data/FileCardData';
import FileCardList from '../../components/file-card/FileCardList';

function ProductImagesTRM01() {

  const request = FileCardData.sort((a,b)=>a.title.localeCompare(b.title)).filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'product image' &&
        item
        .product
        .toLowerCase()
        === 'trm-01'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Product images - TRM-01</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M86 4H10c-3.3 0-6 2.7-6 6v76a6 6 0 0 0 6 6h76c3.3 0 6-2.7 6-6V10a6 6 0 0 0-6-6zm2 82c0 1.084-.916 2-2 2H10c-1.103 0-2-.897-2-2V10c0-1.084.916-2 2-2h76c1.103 0 2 .897 2 2v76zM74 24H22c-3.3 0-6 2.7-6 6v36a6 6 0 0 0 6 6h52c3.3 0 6-2.7 6-6V30a6 6 0 0 0-6-6zm2 42c0 1.084-.916 2-2 2H22c-1.103 0-2-.897-2-2V30c0-1.084.916-2 2-2h52c1.103 0 2 .897 2 2v36zM56 80a2 2 0 0 1-2 2H42a2 2 0 0 1 0-4h12a2 2 0 0 1 2 2z"/>}
          title={'Product images'}
          subtitle={'TRM-01'}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-file-card-list-empty"><span>No files found</span></div>
            : <FileCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(ProductImagesTRM01);